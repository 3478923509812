@import '~node_modules/@stobag/mystobag-shared/src/lib/styles/common-styles';

// Replace this logic with `font-display: block;` after dropping IE support
*.loading-material-icons .mat-icon {
	display: none;
}

.white {
    color: var(--stobag-secondary-color);
}
